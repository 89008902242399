<content-loading-animation *ngIf="loading"></content-loading-animation>

<div class="details-panel" *ngIf="!loading">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ userGroup.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="1x" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="1x" class="d-lg-none"></fa-icon></button
                >{{ userGroup.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Action Buttons -->
            <div class="float-end">
                <div class="btn-group mb-2" role="group">
                    <button type="button" class="btn btn-outline-primary" (click)="edit(userGroup)" title="{{ 'EDIT' | translate }}">
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="deleteUserGroup()" title="{{ 'DELETE' | translate }}">
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="details-form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>NAME</h5>
                                            <div class="col-12">
                                                <span>{{ userGroup.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>DESCRIPTION</h5>
                                            <div class="col-12">
                                                <span>{{ userGroup.description || "-" }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>USERS</h5>
                                            <div class="col-12">
                                                <span>{{ userGroup.users && userGroup.users.length > 0 ? (userGroup.users | brackets) : "-" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-roles">
                    <ngb-panel id="ngb-panel-roles">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'ROLES' | translate }}">ROLES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-roles-table [roles]="userGroupRoles" [showSource]="false"></app-roles-table>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
